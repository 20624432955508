export default {
  'menu.home': '首页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard': '系统桌面',
  'menu.enterGuide': '欢迎页',
  'menu.enterGuide.welcome': '欢迎加入',
  'menu.enterGuide.guide': '入职指引',
  'menu.offer': 'offer邀请邮件',
  'menu.offer.invitation': 'Offer邀请',

  'menu.department': '我的部门',
  'menu.deptTurnover': '离职详情',
  'menu.department.manpower': '人员需求',
  'menu.report': '工作报告',
  'menu.report.list': '报告列表',
  'menu.report.reportDetail': '工作报告详情',
  'menu.report.personDetail': '个人工作报告统计',
  'menu.file': '文件柜',
  'menu.rules': '规章制度',
  'menu.projectTeam': '我的项目组',
  'menu.postInfo': '岗位信息',
  'menu.personalInfo': '个人信息',
  'menu.payroll': '工资单',
  'menu.equipment': '我的设备',
  'menu.onboardingInfo': '入职信息表',
  'menu.working': '欢迎加入',
  'menu.working.welcome': '欢迎页',
  'menu.working.guide': '入职指引',
  'menu.attendance': '考勤数据',
  'menu.attendance.record': '当月考勤记录',
  'menu.attendance.hisrecord': '历史考勤记录',
  'menu.attendance.declaration': '考勤申报',
  'menu.attendance.hisdeclaration': '历史考勤申报',
  'menu.attendance.holiday': '假期统计',

  'menu.approval': '办公审批',
  'menu.approval.my-approval': '我的审批',
  'menu.approval.initiate': '发起审批',
  'menu.approval.quit': '发起离职',
  'menu.approval.restartLeave': '重启离职',
  'menu.approval.autoleave': '员工自离',
  'menu.approval.restartautoleave': '重启自离',
  'menu.approval.rest': '请假审批',
  'menu.approval.renewal': '续签审批',
  'menu.approval.recruit': '本部人员招聘审批',
  'menu.approval.transfer': '调岗',
  'menu.approval.break': '发起调休',
  'menu.approval.projectholiday': '请假审批',
  'menu.approval.restartprojectholiday': '重启请假审批',
  'menu.approval.goout': '发起外出出差审批',
  'menu.approval.gooutapproval': '外出出差审批',
  'menu.approval.restartgoout': '重启外出出差审批',
  'menu.approval.overTime': '发起加班审批',
  'menu.approval.overtimeapproval': '加班审批',
  'menu.approval.restartoverTime': '重启加班审批',
  'menu.approval.patch': '发起补卡审批',
  'menu.approval.patchapproval': '补卡审批',
  'menu.approval.restartpatch': '重启补卡审批',
  'menu.approval.offer': 'offer审批',
  'menu.approval.restartoffer': 'offer重启',
  'menu.approval.purchase': '办公采购',
  'menu.approval.lease': '办公租赁',
  'menu.approval.demand': '需求重启',
  'menu.approval.medical': '体检费报销',
  'menu.approval.proof': '证明开具',
  'menu.approval.reimbursement': '报销',
  'menu.approval.manpower': '本部人力资源',
  'menu.approval.quitapproval': '离职审批详情',
  'menu.approval.autoleaveapproval': '自离审批详情',
  'menu.approval.offerapproval': 'offer审批详情',
  'menu.approval.recruitmentdemand': '本部人员招聘审批详情',
  'menu.approval.hqoffer': '本部offer审批',
  'menu.approval.hqofferdetail': '本部offer审批详情',
  'menu.approval.projectholidayapproval': '请假审批详情',
  'menu.approval.meal': '餐补报销',
  'menu.approval.transport': '交通补贴',
  'menu.approval.transaction': '异动审批',
  'menu.approval.releaseterm': '释放转项审批',
  'menu.approval.restartReleaseterm': '释放转项审批详情',
  'menu.approval.releaseApproval': '释放转项审批',
  'menu.approval.salarychange': '发起员工调薪',
  'menu.approval.salarychangeapproval': '调薪审批',
  'menu.approval.restartsalarychange': '重启员工调薪',

  'menu.personnel': '人事管理',
  'menu.personnel.staff': '人事花名册',
  'menu.personnel.importrecord': '导入记录',
  'menu.personnel.editStaff': '编辑员工信息',
  'menu.personnel.detail': '员工信息详情',
  'menu.personnel.audit': '员工信息审核',
  'menu.personnel.onboardingInfoprint': '入职信息表打印预览',
  'menu.personnel.social': '社保基数',
  'menu.personnel.proof': '证明开具',
  'menu.personnel.print': '证明打印预览',
  'menu.personnel.medical': '体检单',
  'menu.personnel.regular': '转正管理',
  'menu.personnel.regularDetail': '转正员工详情',
  'menu.personnel.regularPrint': '打印转正通知',
  'menu.personnel.renewal': '续签管理',
  'menu.personnel.renewalDetail': '续签员工详情',
  'menu.personnel.turnover': '离职管理',
  'menu.personnel.contractManage': '合同管理',
  'menu.personnel.contractManageDetail': '合同管理详情',
  'menu.personnel.release': '释放管理',
  'menu.personnel.onboard': '入职管理',
  'menu.personnel.onboardInfo': '入职资料详情',
  'menu.personnel.survey': '离职调查',
  'menu.personnel.surveyDetail': '离职调查详情',
  'menu.personnel.setting': '人事设置',
  'menu.personnel.historyChangesList': '历史异动记录',
  'menu.personnel.changesList': '异动记录',
  'menu.personnel.changesInfo': '异动详情',

  'menu.train': '培训管理',
  'menu.train.record': '培训档案',
  'menu.train.material': '培训资料',
  'menu.train.add': '添加培训资料',
  'menu.train.edit': '编辑培训资料',
  'menu.train.question': '题库管理',
  'menu.train.questionDetail': '题库编辑',

  'menu.socialInsurance': '五险一金',
  'menu.socialInsurance.increase': '本月增减表',
  'menu.socialInsurance.comprehensive': '本月综合表',
  'menu.socialInsurance.history': '历史数据',
  'menu.socialInsurance.socialSetting': '社保配置',

  'menu.attendanceManagement': '考勤管理',
  'menu.attendanceManagement.timeSheet': '客户工时',
  'menu.attendanceManagement.monthAttdance': '当月考勤',
  'menu.attendanceManagement.historicalAttendance': '历史考勤',
  'menu.attendanceManagement.attendanceOperation': '考勤操作记录',
  'menu.attendanceManagement.declaration': '考勤申报列表',
  'menu.attendanceManagement.departmentAttendance': '本部员工考勤明细',
  'menu.attendanceManagement.declarationList': '项目员工考勤申报',
  'menu.attendanceManagement.customerHours': '工时导入',
  'menu.attendanceManagement.manHours': '工时核对列表',
  'menu.attendanceManagement.manHoursDetail': '工时核对详情',
  'menu.attendanceManagement.setting': '考勤设置',
  'menu.attendanceManagement.schemes': '考勤方案',
  'menu.attendanceManagement.edit': '编辑考勤方案',
  'menu.attendanceManagement.attendanceFree': '编辑免卡人员',
  'menu.attendanceManagement.annualLeave': '年假统计',
  'menu.attendanceManagement.overtimeStatis': '加班调休记录',
  'menu.attendanceManagement.holiday': '假期统计',
  'menu.attendanceManagement.editholiday': '假期配置',
  'menu.attendanceManagement.editleave': '假期配置',
  'menu.attendanceManagement.editsickleave': '假期配置',
  'menu.attendanceManagement.editannualleave': '假期配置',
  'menu.attendanceManagement.overtime': '添加加班调休规则',
  'menu.attendanceManagement.editOvertime': '编辑加班调休规则',
  'menu.attendanceManagement.statistics': '考勤统计',
  'menu.personnel.manpower': '人力分析',
  'menu.personnel.manpower.structure': '人力结构',
  'menu.personnel.manpower.flow': '流动分析',
  'menu.personnel.manpower.resignation': '离职分析',

  'menu.administrative': '行政管理',
  'menu.administrative.approval-setting': '审批配置',
  'menu.administrative.flowSetting': '审批配置详情',
  'menu.administrative.flowSettingSubpage': '条件流程配置',
  'menu.administrative.announcement': '公告管理',
  'menu.administrative.structure': '组织架构',
  'menu.administrative.duty': '职务级别',
  'menu.administrative.history': '历史归档',
  'menu.administrative.all': '架构一览',
  'menu.administrative.file': '文档管理',
  'menu.administrative.approval': '审批配置',
  'menu.administrative.configure': '审批配置详情',
  'menu.administrative.group': '审批分组',
  'menu.administrative.approvalPDF': '审批PDF下载',
  'menu.administrative.criteria': '审批配置',
  'menu.administrative.restConfig': '添加条件审批流程',
  'menu.administrative.leavePreview': '离职审批流程预览',
  'menu.administrative.proofPreview': '证明开具流程预览',
  'menu.administrative.reimbursementPreview': '报销流程预览',
  'menu.administrative.offerPreview': 'offer审批流程预览',
  'menu.administrative.leasePreview': '资产租赁流程预览',
  'menu.administrative.demandNeedPreview': '人力需求流程预览',
  'menu.administrative.examination': '审批监控',
  'menu.administrative.record': '审批记录',
  'menu.administrative.statistics': '审批统计',
  'menu.administrative.index': '审批设置',
  'menu.administrative.approval-setting': '审批设置',
  'menu.administrative.approval-log': '审批记录',

  'menu.asset': 'IT设备管理',
  'menu.asset.device': '设备列表',
  'menu.asset.distribution': '设备分布',
  'menu.asset.audit': '邮箱分配',
  'menu.administrative.regulations': '规章制度',
  'menu.administrative.newregulation': '新建规章制度',
  'menu.administrative.modifyregulation': '编辑规章制度',

  'menu.business': '业务',
  'menu.business.addOpportunity': '添加商机',
  'menu.business.opportunity': '商机列表',
  'menu.business.editOpportunity': '编辑商机',
  'menu.business.oppDetail': '商机详情',
  'menu.business.addCustomer': '添加客户',
  'menu.business.customer': '客户列表',
  'menu.business.customerDetail': '客户详情',
  'menu.business.editCustomer': '编辑客户',
  'menu.business.addContact': '添加客户联系人',
  'menu.business.editContact': '编辑客户联系人',
  'menu.business.contactDeatil': '客户联系人详情',
  'menu.business.contractList': '合同列表',
  'menu.business.addContract': '添加合同',
  'menu.business.editContract': '编辑合同',
  'menu.business.contractDetail': '合同详情',
  'menu.business.contractReview': '合同评审',
  'menu.business.project': '项目管理',

  'menu.commercial': '商务',
  'menu.commercial.staffWorkHour': '工时导入',
  'menu.commercial.checkDepDetail': '员工工时列表',
  'menu.commercial.checkDetail': '工时详情',
  'menu.commercial.operationLogDetails': '工时核对操作记录',
  'menu.commercial.manHours': '工时核对列表',
  'menu.commercial.manHoursDetail': '工时核对详情',
  'menu.commercial.pay': '付费表确定',
  'menu.commercial.leaveList': '离项工时',
  'menu.commercial.payMonth': '付费月统计表',
  'menu.commercial.grossprofit': '毛利表',
  'menu.commercial.contract': '合同列表',

  'menu.project': '项目',
  'menu.project.manage': '项目管理',
  'menu.project.projectAttendanceManagement': '项目考勤管理',
  'menu.project.projectDeclareTheDetails': '（交付）确认申报详情',
  'menu.project.TimeCheckDetails': '工时核对详情',
  'menu.project.detail': '项目详情',
  'menu.project.turnover': '离项员工详情',
  'menu.project.hisdeclaration': '考勤申报详情',
  'menu.project.changeRecord': '异动记录',
  'menu.project.releaseForm': '释放沟通情况表',
  'menu.project.transfer': '异动管理',
  'menu.project.transaction': '异动审批',
  'menu.project.delayWage': '工资缓发审批',
  'menu.project.demand': '需求表',
  'menu.project.addDemand': '新建线下需求',
  'menu.project.proschedule': '计划表',
  'menu.project.candidateFollowUp': '候选人跟进',
  'menu.project.allot': '待分配需求',
  'menu.project.allotNew': '待分配需求new',
  'menu.project.release': '释放管理',
  'menu.project.resourcePool': '资源池',
  'menu.project.resumeDetail': '简历详情',
  'menu.project.operatingResources': '运营资源',
  'menu.project.projectPersonnel': '项目人员',
  'menu.project.planTable': '交付计划表',
  'menu.project.requirements': '系统需求导入',
  'menu.project.setting': '跟进设置',

  'menu.docking': '外部系统',
  'menu.docking.unallocateDemand': '待分配需求',
  'menu.docking.resumedownload': '推荐简历下载',
  'menu.docking.follow': '候选人跟进',

  'menu.recruit': '招聘',
  'menu.recruit.departmentCandidates': '本部候选人跟进',
  'menu.recruit.position': '职务',
  'menu.recruit.position.commissioner': '招聘专员任务',
  'menu.recruit.position.manager': '招聘经理任务分配',
  'menu.recruit.position.director': '招聘总监任务分配',
  'menu.recruit.position.requirementss': '候选人详情测试',
  'menu.recruit.position.requirements': '候选人详情',
  'menu.recruit.list': '人才简历库',
  'menu.recruit.releasedb': '释放人才库',
  'menu.recruit.log': '操作记录',
  'menu.recruit.add': '添加简历',
  'menu.recruit.Resumepush': '简历推送',
  'menu.recruit.lockResume': '锁定简历',
  'menu.recruit.interviewResult': '面试结果',
  'menu.recruit.ResumeInvite': '简历邀请任务',
  'menu.recruit.edit': '编辑简历',
  'menu.recruit.detail': '简历详情',
  'menu.recruit.project': '项目管理',
  'menu.recruit.statistics': '统计分析',
  'menu.recruit.statistics.analysis': '招聘统计',
  'menu.recruit.statistics.abnormal': '预警异常',
  'menu.recruit.statistics.automatic': '自动需求池',
  'menu.recruit.manage': '设置',
  'menu.recruit.manage.plan': '招聘计划',
  'menu.recruit.manage.position': '职务设置',
  'menu.recruit.manage.channel': '渠道设置',
  'menu.recruit.manage.warning': '预警设置',
  'menu.recruit.schedule': '招聘需求池',
  'menu.recruit.release': '释放管理',
  'menu.recruit.setting': '招聘设置',
  'menu.recruit.headquarterDemand': '本部需求表',
  'menu.project.interactiveplanTable': '交付计划表',

  'menu.finance': '财务',
  'menu.finance.pre': '税前工资',
  'menu.finance.after': '税后工资',
  'menu.finance.individual': '个税表',
  'menu.finance.historySalary': '历史工资表',
  'menu.finance.historyDetail': '历史工资详情',
  'menu.finance.person': '员工工资单',
  'menu.finance.invoice': '开票申请',
  'menu.finance.print': '开票信息打印',
  'menu.finance.back': '客户回款',
  'menu.finance.contract': '客户合同',
  'menu.finance.transfer': '员工异动表',
  'menu.finance.operate': '操作记录',

  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.profile.detail': '员工详情',
  'menu.profile.edit': '员工信息编辑',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人中心',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',

  'menu.system': '系统通知',
  'menu.system.news': '消息',
  'menu.system.releasenews': '释放通知单',
  'menu.system.refuseRepeat': '拒绝复推告知函',
  'menu.system.todo': '待办任务',
  'menu.system.departure': '离项任务',
  'menu.system.confirmDeparture': '离项任务',
  'menu.system.hourConfirmation': '离项工时确认',
  'menu.system.releaseGuidelines': '释放指引',
  'menu.system.replynotice': '复推告知函',
  'menu.system.feedback': '确认付费时间',
  'menu.system.needInvite': '交付推送任务',
  'menu.system.renewalBack': '续签反馈',
  'menu.system.demandClose': '线上需求关闭',
  'menu.system.renewal': '续签审批',
  'menu.system.renewalTask': '续签通知书',
  'menu.system.leaveSurvey': '离职调查表',
  'menu.system.leaveSure': '离职确认表',
  'menu.system.leaderSureForStaff': '离职确认表',
  'menu.system.staffLeaveSigned': '离职会签单',
  'menu.system.attendanceLeaveSigned': '考勤专员-离职会签单',
  'menu.system.socialLeaveSigned': '社保专员-离职会签单',
  'menu.system.equLeaveSigned': '设备管理员-离职会签单',
  'menu.system.financialLeaveSigned': '财务-离职会签单',
  'menu.system.seniorLeaveSigned': '离职会签单审签',
  'menu.system.seniorLeaveAuthorized': '离职会签单签批',
  'menu.system.entrytask': '候选人跟进任务',
  'menu.system.joinproject': '约定入项时间',
  'menu.system.join': '入项确认',
  'menu.system.holidaytask': '请假证明补充任务',
  'menu.system.abnormalTask': '初核异常任务',
  'menu.system.returnDevice': '归还设备任务',
  'menu.system.turnover': '转正申请',
  'menu.system.turnoverDetail': '转正申请详情',
  'menu.system.missionDetails': '转正任务',
  'menu.system.proofDetail': '证明开具申请详情',
  'menu.system.reProof': '重启证明开具',
  'menu.system.delayWage': '工资缓发审批详情',
  'menu.system.reDelayWage': '重启工资缓发',
  'menu.system.transaction': '异动审批详情',
  'menu.system.retransaction': '重启异动审批',
  'menu.system.reimbursementDetail': '报销流程详情',
  'menu.system.reReimbursement': '重启报销流程',
  'menu.system.approval': '我的审批',
  'menu.system.setting': '设置',
  'menu.system.announcement': '系统公告',
  'menu.feedBack': '吐个槽',
  'menu.feedBackHistory': '历史吐槽',

  'menu.setting': '系统设置',
  'menu.setting.user': '用户管理',
  'menu.setting.userDetail': '用户详情',
  'menu.setting.role': '职务权限',
  'menu.setting.menuManage': '菜单管理',
  'menu.setting.roleSetting': '职务详情',
  'menu.setting.message': '消息设置',
  'menu.setting.textMessage': '标签设置',
  'menu.setting.template': '模板管理',
  'menu.setting.loginLog': '登录日志',
  'menu.setting.systemFeedBack': '用户反馈',
  'menu.setting.feedBackDetail': '用户反馈详情',
  'menu.setting.updateDynamics': '更新动态',
  'menu.setting.serviceAgreement': '服务协议',
  'menu.setting.dataDictionary': '数据字典',
  'menu.setting.dataKeyValue': '数据键值',
  'menu.setting.operation': '操作记录',
  'menu.setting.approval': '审批日志',
  'menu.setting.system': '系统日志',
  'menu.setting.export': '导出日志'
};
